<template>
  <VMenu left :close-on-content-click="false" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <VBtn class="mr-2" fab outlined small color="green" elevation="2" v-on="on" v-bind="attrs">
        <VIcon color="green">
          mdi-information
        </VIcon>
      </VBtn>
    </template>
    <VCard width="550">
      <VCardTitle>
        {{ $t('auth.howToActivateAccount') }}
      </VCardTitle>
      <VDivider />
      <VCardText class="py-0">
        <v-timeline>
          <v-timeline-item small color="info">{{ $t('auth.checkMail') }}</v-timeline-item>
          <v-timeline-item class="text-right" small color="warning">
            {{ $t('auth.activeAccount') }}
          </v-timeline-item>
          <v-timeline-item small color="success">{{ $t('auth.successActivated') }}</v-timeline-item>
        </v-timeline>
      </VCardText>
    </VCard>
  </VMenu>
</template>

<script>
import lang from '../../mixins/language';

export default {
  mixins: [lang],
};
</script>

<style></style>
